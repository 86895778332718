<template>
  <b-modal centered @hidden="hide" body-class="p-4" v-model="isShowModal">
    <template #modal-header>
      <div class="ft-black">{{ title }}</div>
    </template>
    <div>
      <label class="font-weight-bold mb-2">Start Date - End Date</label>
      <div class="date-input">
        <span
          v-if="!form.start_date"
          @click="$refs.dateStart.isOpen = true"
          class="w-40 pointer"
          >DD/MM/YYYY</span
        >
        <span
          v-else
          @click="$refs.dateStart.isOpen = true"
          class="w-50 pointer"
          >{{ form.start_date | moment($formatDateNew) }}</span
        >
        <span class="px-2">-</span>
        <span
          v-if="!form.end_date"
          @click="$refs.dateEnd.isOpen = true"
          class="w-100 pointer"
          >DD/MM/YYYY</span
        >
        <span
          v-else
          @click="$refs.dateEnd.isOpen = true"
          class="w-100 pointer"
          >{{ form.end_date | moment($formatDateNew) }}</span
        >
        <div :class="'icon-primary text-right'">
          <font-awesome-icon
            icon="calendar-alt"
            @click="$refs.dateStart.isOpen = true"
            :class="'cursor-pointer color-primary'"
            color="#B41BB4"
          />
        </div>
      </div>
      <datetime
        ref="dateStart"
        type="date"
        format="dd/MM/yyyy"
        value-zone="Asia/Bangkok"
        v-model="form.start_date"
        :max-datetime="form.end_date || dateFormat()"
      >
      </datetime>
      <datetime
        type="date"
        format="dd/MM/yyyy"
        value-zone="Asia/Bangkok"
        :max-datetime="dateFormat()"
        :min-datetime="form.start_date"
        ref="dateEnd"
        v-model="form.end_date"
      >
      </datetime>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click="hide" class="btn-cancel"> Cancel </b-button></b-col
        >
        <b-col
          ><b-button
            class="btn-confirm"
            @click.prevent="exportTemplate"
            :isLoading="isLoading"
          >
            Export
          </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isShowModal: false,
      form: {
        search: "",
        start_date: "",
        end_date: "",
        status_id: [1],
      },
      apiUrl: "",
      isLoading: false,
    };
  },
  methods: {
    show(data) {
      this.apiUrl = data.apiUrl;
      this.form = {
        search: data.search,
        start_date: data.start_date,
        end_date: data.end_date,
        status_id: [1],
      };
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },

    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    async exportTemplate() {
      this.$bus.$emit("showLoading");

      const payload = {
        ...this.form,
        start_date: this.form.start_date
          ? this.$moment(this.form.start_date).format("YYYY-MM-DD")
          : this.form.start_date,
        end_date: this.form.end_date
          ? this.$moment(this.form.end_date).format("YYYY-MM-DD")
          : this.form.end_date,
      };

      await this.axios.post(
        `${process.env.VUE_APP_REPORT_API}/Transaction${this.apiUrl}`,
        payload,
        {
          responseType: "blob",
        }
      );

      this.$bus.$emit("hideLoading");
      this.confirmAlert({
        message: "Do you want to download the file now?",
        title: "Success !",
        icon: "success",
        confirmButtonText: "Go To Page",
        cancelButtonText: "Close",
      }).then((val) => {
        if (val.isConfirmed) {
          const routeData = this.$router.resolve({
            name: "Customer Report List",
          });
          window.open(routeData.href, "_blank");
        }
      });
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.date-input {
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .vdatetime-input {
  display: none;
}
</style>
